.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;

  .custom-radio input[type="radio"] {
    display: none;
  }

  .custom-radio .radio-label {
    padding-left: 10px;
    text-decoration: none;
  }

  .custom-radio input[type="radio"]:checked + .radio-label {
    text-decoration: underline;
    font-weight: 700;
  }
}
