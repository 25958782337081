@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/inter-font.ttf") format("truetype");
}

.App {
  text-align: center;
}

.App-logo {
  height: 5rem;
  pointer-events: none;

  @media (min-width: 640px) { 
    height: 7rem;
  }
}

.App-header {
  background-color: #695BD6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: white;
}

.App-link {
  color: #61dafb;
  font-size: 24px;
}

html {
  scroll-behavior: smooth;
}
