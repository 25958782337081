.treatment-card {
    align-items: center;
    justify-content: center;

    &:hover {
        .treatment-content {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.6);
            border-radius: 0.75rem;
            padding: 1rem;
            margin: 0.5rem;
            width: 92%;

            @media (max-width: 768px) {
                height: 88%;
                width: 96%;
            }

            @media (min-width: 768px) {
                margin: 0.75rem;
                height: 92%;
            }

            @media (min-width: 1024px) {
                margin: 0.75rem;
                height: 95%;
            }

            @media (min-width: 1280px) {
                margin: 1rem;
                height: 92%;
            }

            @media (min-width: 1800px) {
                margin: 1.5rem;
            }

            .treatment-name {
                display: none;
            }

            .treatment-desc {
                display: block;
            }

            .arrow-bg {
                display: none;
            }

            .treatment-arrow-icon {
                display: flex;
                position: absolute;
                font-size: 1.12rem;
                right: 1rem;
                bottom: 1rem;

                @media (min-width: 450px) {
                    font-size: 1.25rem;
                }

                @media (min-width: 640px) {
                    font-size: 2rem;
                }

                @media (min-width: 768px) {
                    font-size: 1.25rem;
                    right: 1.5rem;
                    bottom: 1.5rem;
                }

                @media (min-width: 1024px) {
                    font-size: 1.5rem;
                }

                @media (min-width: 1280px) {
                    font-size: 2rem;
                }

                @media (min-width: 1536px) {
                    font-size: 2.5rem;
                }

                @media (min-width: 1800px) {
                    font-size: 2.8rem;
                }
            }
        }
    }
}