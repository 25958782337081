#braintree-drop-in-div {
  .braintree-form__field-group {
    margin-bottom: 1.5rem;
  }
  .braintree-form__label {
    font-size: 16px !important;
    margin-bottom: 12px;
  }
  input {
    font-size: 23px !important;
  }
  .braintree-card {
    border: 0px !important;
    display: flex;
  }
  .braintree-sheet__header {
    padding: 0;
    border: 0;
  }
  .braintree-sheet__header-label {
    visibility: hidden;
    position: absolute;
    z-index: -10000;
  }
  .braintree-sheet__icons {
    visibility: hidden;
    position: absolute;
    z-index: -1000;
  }
  .braintree-form__field-group {
    padding-left: 0;
    margin-right: 1rem;
  }
  .braintree-sheet__content {
    padding: 0;
  }
  .braintree-form__hosted-field {
    border: 1px solid;
    border-color: #d7d7d7;
    border-radius: 0.25rem;
  }
  @media (min-width: 1536px) {
    .braintree-form__hosted-field {
      height: 4.3rem;
      padding: 0px 1.5rem;
    }
  }
  .braintree-form__notice-of-collection {
    color: blue;
    font-weight: 500;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .cardholderName {
    background-color: aqua !important;
  }
}

.billing-address-checkbox {
  width: 1.7rem;
  height: 1.7rem;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #d7d7d7;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}
.billing-address-checkbox:checked {
  background-color: #695bd6;
}

.terms-scroll {
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 100%;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 100%;
  }

  &::-webkit-scrollbar-thumb {
    background: #f5d447;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a89230;
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
  max-width: 800px;
}
